import { Component, OnInit,Output,EventEmitter, Input } from '@angular/core';
import { Site } from 'app/shared/models/site.model';
import { ApiService } from 'app/shared/services/api.service';

@Component({
  selector: 'app-select-site',
  templateUrl: './select-site.component.html',
  styleUrls: ['./select-site.component.scss'],
})
export class SelectSiteComponent implements OnInit {
  Sites: Site[] = [];
  SitesCopy: Site[] = [];
  @Input() site:Site={
    siteId: 0,
    siteName: '',
    siteShortcut: '',
    countryId: 0,
    companyId: 0,
    latitude: '',
    longitude: '',
    storeNumber: ''
  };
  @Input() full=false;
  @Output() advanceSearch:EventEmitter<any> =new EventEmitter<any>();
  @Output() refresh:EventEmitter<any> =new EventEmitter<any>();
  constructor(private api: ApiService) {}
  ngOnInit() {
    this.getSite();
  }
  getSite() {
    this.api.getSites().subscribe((res) => {
      if (res.status == 200) {
        res.body.data.forEach((ele) => {
          let row: any = {
            siteName: ele.siteName,
            siteShortcut: ele.siteShortcut,
            siteId: ele.siteId,
            countryId: ele.countryId,
          };
          this.Sites.push(row);
        });
        this.SitesCopy = this.Sites.slice();
      }
    });
  }
  AdvancedSearch(event){
    this.advanceSearch.emit({site:this.site,siteId:this.site.siteId})
  }
  Refresh(){

    this.refresh.emit();
  }
}
