import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm',
  template: `<h1 matDialogTitle class="mb-8 message">{{ data.title }}</h1>
    <div mat-dialog-content class="mb-16 message">{{ data.message }}</div>
    <div mat-dialog-actions class="pb-16">
    <button
    type="button"
    class=submit
    (click)="dialogRef.close(true)">OK</button>
    &nbsp;
    <span fxFlex></span>
    <button
    type="button"
    class="cancel"
    (click)="dialogRef.close(false)">Cancel</button>
    </div>`,
  styles: [
    ` button.cancel {
        width: 40% !important;
        height: 44px !important;
        color: white !important;
        background-color: #ff8a48 !important;
        border: none !important;
        cursor: pointer;
        outline: none;
        text-align: center;
        font-size: 14px;
        border-radius: 4px;
      }`, `
      button.submit {
        width: 40% !important;
        height: 44px !important;
        color: white !important;
        background-color: #00c1db !important;
        border: none !important;
        cursor: pointer;
        outline: none;
        text-align: center;
        font-size: 14px;
        border-radius: 4px;
      }`,
    `.message{
        text-align: center;
      }`

  ]
})
export class AppComfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<AppComfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
}