<div class="sidebar-panel">
    <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column" fxLayoutAlign="space-between">
        <div class="sidebar-hold">

            <!-- App Logo -->
            <div class="branding">
                <img src="assets/images/Logo.png" alt="user img" class="app-logo">
                <div
                class="sidebar-compact-switch"
                [ngClass]="{active: layoutConf.sidebarCompactToggle}"
                (click)="toggleCollapse()"
                *ngIf="layoutConf.sidebarStyle !== 'compact'"><span></span></div>

            </div>
            <!-- Sidebar user -->
            <div class="app-user">
                <div class="app-user-photo">
                    <img [src]="image?image:'assets/images/Logo.jpeg'" alt="user img" style="border-radius:50%" class="mat-elevation-z1" alt="">
                    </div>
                <span class="app-user-name mb-8">
                <!-- <mat-icon class="icon-xs text-muted">lock</mat-icon> -->
                {{user.username}} 
               </span>
               <p class="app-user-role mb-8">{{user.userTypeInfo.userType}}</p>
                <div class="app-user-controls">
              
                </div>
            </div>
           
            <!-- Navigation -->
            <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>
          
        </div>
          <!--Logout-->
          <div class="lvl1 footer-logout" (click)="logout()">

            <img src="assets/images/logout.svg" class="svgIcon" width="24px" height="24px" />
            <span fxLayout="row" fxLayoutAlign="start center" class="item-name lvl1">Log out</span>
            <span fxFlex></span>  
                 </div>
    </div>
</div>
<app-logout [openAdd]="openLogout" (close)="closeLogout()"></app-logout>