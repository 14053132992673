import { Injectable } from '@angular/core';
import { LocalStoreService } from '../local-store.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { map, catchError, delay } from 'rxjs/operators';
import { User } from '../../models/user.model';
import { of, BehaviorSubject, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { ApiService } from '../api.service';
import { DeviceUUID } from 'device-uuid';

// ================= only for demo purpose ===========

// ================= you will get those data from server =======

@Injectable({
  providedIn: 'root',
})
export class JwtAuthService {
  token;
  isAuthenticated: Boolean;
  user: any = {};
  user$ = new BehaviorSubject<User>(this.user);
  signingIn: Boolean;
  return: string;
  JWT_TOKEN = 'JWT_TOKEN';
  REFJWT_TOKEN = 'REF_JWT_TOKEN';
  APP_USER = 'USER';
  USER_TYPE = 'USER_TYPE';
  Status: any[] = [];
  Countries: any[] = [];
  Types: any[] = [];
  Manufacturer: any[] = [];
  uuid=''
  constructor(
    private ls: LocalStoreService,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService
  ) {
    this.route.queryParams.subscribe((params) => (this.return = params['return'] || '/'));
  }

  public signin(username, password) {
    this.signingIn = false;
    this.uuid = new DeviceUUID().get();
    return this.api.Login(username, password,this.uuid).pipe(
      map((res: any) => {
        if (res.status == 200) {
          this.ls.setItem('uuid',this.uuid)
          let user = { username, password };
          this.setUserAndToken(res.body.token, res.body.refreshtoken, res.body.userTypeInfo.userTypeId, user, !!res.ok);
          this.ls.setItem('USER_DATA', res.body);
          this.signingIn = true;
          return res.body;
        }
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /*
    checkTokenIsValid is called inside constructor of
    shared/components/layouts/admin-layout/admin-layout.component.ts
  */
  public checkTokenIsValid() {}

  public signout(allDevices: boolean) {
    return this.api.logout(allDevices).subscribe((res) => {
      if (res.status == 200) {
        this.setUserAndToken(null, null, null, null, false);
        this.ls.removeItem('USER_TYPE');
        this.ls.clear();
        ApiService.Token = '';
        ApiService.access_type = '';
        this.router.navigate(['sessions/signin']);
        this.router.navigateByUrl('sessions/signin');
        return res.body;
      } else {
        catchError((error) => {
          this.router.navigate(['sessions/signin']);
          this.router.navigateByUrl('sessions/signin');

          return throwError(error);
        });
      }
    });
  }

  isLoggedIn(): Boolean {
    return !!this.getJwtToken();
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
  getRefJwtToken() {
    return this.ls.getItem(this.REFJWT_TOKEN);
  }
  getUser() {
    return this.ls.getItem('USER_TYPE');
  }
  getUserData() {
    return this.ls.getItem('USER_DATA');
  }

  setUserAndToken(token: string, refToken: string, user_type: any, user: any, isAuthenticated: Boolean) {
    ApiService.Token = token;
    ApiService.user_type = user_type;
    ApiService.RefreshToken = refToken;
    ApiService.access_type = 'bearer';
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.user = user;
    this.user$.next(user);
    this.ls.setItem(this.JWT_TOKEN, token);
    this.ls.setItem(this.REFJWT_TOKEN, refToken);
    this.ls.setItem('REF_JWT_TOKEN', refToken);
    this.ls.setItem(this.APP_USER, user);
    this.ls.setItem('USER_TYPE', user_type);
  }
}
