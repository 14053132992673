<mat-toolbar class="topbar mat-bg-card">
  <!-- Sidenav toggle button -->
  <button
    *ngIf="layoutConf.sidebarStyle !== 'compact'"
    mat-icon-button
    id="sidenavToggle"
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <!-- Language Switcher -->
  <button mat-button class="services">
    <!-- <mat-icon style="color: #00c1db">settings</mat-icon> -->
    <mat-select
      placeholder=""
      id="langToggle"
      [style.width]="'auto'"
      name="currentservice"
      [(ngModel)]="currentService"
      (selectionChange)="setService()"
      class="topbar-button-right"
    >
      <mat-option *ngFor="let item of services$" [value]="item.serviceType" ngDefaultControl>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10">
          <img [src]="item.serviceImage?item.serviceImage:''" width="24px" height="24px" [alt]="item.serviceImage" />
          <span  style="color: #475376 !important">{{ item.serviceName }}</span>
        </div>
       </mat-option
      >
    </mat-select>
  </button>
  <span fxFlex></span>

  <!--
<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let lang of services$" (click)="setService(lang)">
        <mat-icon style="color:#00c1db">settings</mat-icon>
        <span>{{lang.serviceName}}</span>
  </button>
</mat-menu> -->

  <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

  <!-- Notification toggle button -->
  <button
    mat-icon-button
    matTooltip="Notifications"
    (click)="toggleNotific()"
    [style.overflow]="'visible'"
    class="topbar-button-right"
  >
    <!-- <mat-icon>notifications</mat-icon> -->
    <img src='assets/images/notiy.png' width="32px" style="border-radius: 50%" alt="" />
    <!-- <span class="notification-number mat-bg-warn">3</span> -->
  </button>
  <!-- Top left user menu -->
  <button mat-icon-button class="topbar-button-right img-button" [routerLink]="['/profile/overview']">
    <img src='assets/images/profile.png' style="border-radius: 50%" alt="" />
  </button>
  <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right img-button">
    <img src='assets/images/more.png' style="border-radius: 50%" alt="" />
  </button>

  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item>
      <mat-icon>settings</mat-icon>
      <span>Account Settings</span>
    </button>
    <button mat-menu-item>
      <mat-icon>notifications_off</mat-icon>
      <span>Disable alerts</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{ "SIGNOUT" | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>
<app-logout [openAdd]="openLogout" (close)="closeLogout()"></app-logout>