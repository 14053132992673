import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NavigationService } from '../../../shared/services/navigation.service';
import { ThemeService } from '../../services/theme.service';
import { Subscription } from 'rxjs';
import { ILayoutConf, LayoutService } from 'app/shared/services/layout.service';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { ApiService } from 'app/shared/services/api.service';
@Component({
  selector: 'app-sidebar-side',
  templateUrl: './sidebar-side.component.html',
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;
  openLogout:boolean=false

  image: any;
  user:any
  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private layout: LayoutService,
    public jwtAuth: JwtAuthService,
    private ls: LocalStoreService,
    private api: ApiService
  ) {
    this.image = this.ls.getItem('image');
    this.user = this.ls.getItem('USER_DATA');
    if (this.ls.getItem('image') == null) {
      this.api.getUserProfile().subscribe((res) => {
        this.ls.setItem('image', res.body.image);
        this.image = this.ls.getItem('image');
      });
    }
    this.api.getObservable().subscribe((data) => {
 
      if(data.ImgChange){
        this.image = this.ls.getItem('image');
      }
    });
  }

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    if (ApiService.user_type !== 1) {
      this.menuItemsSub = this.navService.menuItemsNotAdmin$.subscribe((menuItem) => {
        if(ApiService.user_type !== 4){
          this.menuItems = menuItem.filter(ele=>ele.tooltip !='Table')
        }else
        {this.menuItems = menuItem.filter(ele=>ele.tooltip !='Tables');}
        this.hasIconTypeMenuItem = !!this.menuItems.filter((item) => item.type === 'icon' || item.type === 'flag')
          .length;
      });
    } else {
      this.menuItemsSub = this.navService.menuItems$.subscribe((menuItem) => {
        this.menuItems = menuItem;
        this.hasIconTypeMenuItem = !!this.menuItems.filter((item) => item.type === 'icon' || item.type === 'flag')
          .length;
      });
    }

    this.layoutConf = this.layout.layoutConf;
  }
  ngOnChanges() {
    this.image = this.api.apiUrlImage + '/' + this.ls.getItem('image');
    if (this.ls.getItem('image') == null) {
      this.api.getUserProfile().subscribe((res) => {
        this.ls.setItem('image', res.body.data.image);
        this.image = this.api.apiUrlImage + '/' + this.ls.getItem('image');
      });
    }
    this.api.getObservable().subscribe((data) => {
 
      if(data.ImgChange){
        this.image = this.ls.getItem('image');
      }
    });
  }
  ngAfterViewInit() {}
  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
  toggleCollapse() {
    if (this.layoutConf.sidebarCompactToggle) {
      this.layout.publishLayoutChange({
        sidebarCompactToggle: false,
      });
    } else {
      this.layout.publishLayoutChange({
        // sidebarStyle: "compact",
        sidebarCompactToggle: true,
      });
    }
  }
  
  logout() {
    this.openLogout = true
  }
  closeLogout() {
    this.openLogout = false
  }
}
