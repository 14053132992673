<div
    (click)="closeModalTouch($event)"
    #myModal
    *ngIf="openAdd == true"
>
    <div
        (click)="$event.stopPropagation()"
        class="modal"
    >
        <div
            class="modal-content"
            style="position: relative"
        >
            <!-- <span
                (click)="closeModal($event)"
                class="material-icons"
                style="position: absolute; top: 11px; right: 7px"
            >
                close
            </span> -->
            <img
            src="assets/images/exit.png"
            class="exit"
            (click)="closeModal($event)"
        >
            <div
            class="full-width"
                fxLayout="column"
                fxLayoutAlign="space-between center"
            >
                <div
                class="full-width"
                    fxLayout="column"
                    fxLayoutAlign="center start"
                    fxLayoutGap="20px"
                >
                    <span
                    class="full-width"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        fxLayoutGap="10px"
                    >
                        <img class="logo" src="assets/images/Logo.png"  />
                    </span>
                    <span
                    class="full-width"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        fxLayoutGap="10px"
                    >
                       <p class="sureLogout">Are you sure to logout?</p>
                    </span>
                    <!-- <mat-slide-toggle
                        class="slide-toggle-logout"
                        color="primary"
                        (change)="onChange($event)"
                        [(ngModel)]="checked"
                        style="font-size:12px"
                    >
                        Logout From All Devices!
                    </mat-slide-toggle> -->
                </div>



                <div
                    class="btns"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    fxLayoutGap="20px"
                >
                    <button
                        class="no"
                        (click)="clickYesOrNo(true)"
                    >
                        All Devices
                    </button>
                    <button
                        class="yes"
                        (click)="clickYesOrNo(false)"
                    >
                        Just this
                    </button>


                </div>
            </div>
        </div>
    </div>
</div>
