export enum DEVICES_STATUS {
  YEAR = 0,
  MONTH = 1,
  WEEK = 2,
}

export enum DEVICE_INNER_WINDOW_SIZE {
  SCREEN = 2880, 
  LABTOP = 1170,
  TABLET = 720,
  MOBILE = 0,
}

export enum ACCOUNT_USER_ROLE {
  ADMIN = 1,
  CLIENT = 2,
  TECHNICAL = 3,
  ENGINEER = 4,
  SUBCONTRACTER = 5,
  SERVICEDELIVERYMANAGER = 6
}
export enum TYPE {

}
export interface materials {
  brand: string,
  description: string,
  msId: number,
  price: number
  type: string,
  unit: string
}
export interface services {
  brand: string,
  description: string,
  msId: number,
  price: number
  type: string,
  unit: string
} 
export enum CURRENCY {
  'AED',
} 
export enum ACTIONS {
  EDIT_PERMISSIONS = 'edit_permissions',
  EDIT = 'edit',
  EDIT_PARK = 'edit_park',
  SHOW_PAYMENTS = 'payment',
  VIEW = 'view details',
  SAVE = 'save',
  DELETE = 'delete',
  ASSING_ROLE = 'Assign Role',
  CLOSE_TICKET = 'Close Ticket',
  DOWNLOAD = 'Download'
}
