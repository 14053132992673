<div class="filter_select_box">
    <mat-select
        [placeholder]="placeHolder"
        [(value)]="selectedValue" [(ngModel)]="selectedValue" (selectionChange)="selectChange.emit(selectedValue)"
    >
    
        <input  class="filter-select" placeholder="Search here ..." [(ngModel)]="searchText" (keydown)="filterOptions()" (input)="filterOptions()" />
        <div>
        <mat-option
            style="color:#3d4866 !important;"
            class="filter-select"
            *ngFor="let item of filteredOptions"
            [value]="item"
        >
            {{item.viewValue}}
        </mat-option>
        </div>
    </mat-select>
</div>