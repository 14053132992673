import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ApiService } from './shared/services/api.service';
import { RoutePartsService } from './shared/services/route-parts.service';

import { filter } from 'rxjs/operators';
import { UILibIconService } from './shared/services/ui-lib-icon.service';
import { LayoutService } from './shared/services/layout.service';
import { JwtAuthService } from './shared/services/auth/jwt-auth.service';
import { LocalStoreService } from './shared/services/local-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'Limitless';
  pageTitle = '';

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private iconService: UILibIconService,
    private layoutService: LayoutService,
    private jwt: JwtAuthService,
    private ls: LocalStoreService,
    private api: ApiService,
  ) {
    iconService.init();
    //TOKEN
    if(ApiService.Token === ""){
      let token  = this.ls.getItem('JWT_TOKEN');
      let ref_token  = this.ls.getItem('REF_JWT_TOKEN');
      if(token){
        // this.jwt.JWT_TOKEN = token;
        ApiService.Token  = token;
      }else 
      if(ref_token){
        // this.jwt.JWT_TOKEN = ref_token;
        ApiService.RefreshToken  = ref_token;

      }
      else{
        this.ls.clear();
        this.router.navigateByUrl("sessions/signin");
      }
    }
    //END TOKEN
    if(ApiService.user_type === -1){
      let userType  = this.ls.getItem('USER_TYPE');
      if(userType){
        this.jwt.USER_TYPE = userType;
        ApiService.user_type  = userType;
      }
      else{
        // this.ls.clear();
        // this.router.navigateByUrl("sessions/signin");
      }
    }
    //STATUS Types Sites
    if(this.jwt.Status.length == 0 || this.jwt.Countries.length == 0|| this.jwt.Types.length == 0 || this.jwt.Manufacturer.length == 0){
      let Status  = this.ls.getItem('Status');
      let Countries  = this.ls.getItem('Sites');
      let Types  = this.ls.getItem('Types');
      let Manufacturer  = this.ls.getItem('Manufacturer');
      if(Status){
        this.jwt.Status = Status;
      }
     
      if(Countries){
        this.jwt.Countries = Countries;
      }
      else{
        this.ls.clear();
        this.router.navigateByUrl("sessions/signin");
      }
      if(Types){
        this.jwt.Types = Types;
      }
     
      if(Manufacturer){
        this.jwt.Manufacturer = Manufacturer;
      }
      else{
        this.ls.clear();
        this.router.navigateByUrl("sessions/signin");
      }
    }
   
  }

  ngOnInit() {
    this.changePageTitle();
    // this.themeService.applyMatTheme(this.layoutService.layoutConf.matTheme);
  }

  ngAfterViewInit() {
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }
      // Extract title from parts;
      this.pageTitle = routeParts
                      .reverse()
                      .map((part) => part.title )
                      .reduce((partA, partI) => {return `${partA} > ${partI}`});
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }
}
