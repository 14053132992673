import { Component, OnInit, EventEmitter, Input, ViewChildren, Output, Renderer2 } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { ApiService } from 'app/shared/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html',
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  public availableLangs = [
    {
      name: 'EN',
      code: 'en',
      flag: 'flag-icon-us',
    },
  ];
  currentService = 1;
  services$: any[] = [];
  currentLang = this.availableLangs[0];
  openLogout: boolean = false
  public Themes;
  public layoutConf: any;
  Image: any;
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    public jwtAuth: JwtAuthService,
    private ls: LocalStoreService,
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private Router: Router,
    private dialog: MatDialog
  ) {
    this.api.getObservable().subscribe((data) => {
      if (data.serviceId > 0) {
        this.currentService = data.serviceId;
      }
      if (data.ImgChange) {
        this.Image = this.ls.getItem('image');
      }
    });
  }
  ngOnInit() {
    this.services$ = this.ls.getItem('ServiceTypes');
    this.getServices();
    this.Image = this.ls.getItem('image');
    this.Themes = this.themeService.Themes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang.code);
    if (this.ls.getItem('image') == null) {
      this.api.getUserProfile().subscribe((res) => {
        this.ls.setItem('image', res.image);
        this.Image = this.ls.getItem('image');
      });
    }
  }
  setLang(lng) {
    ApiService.serviceId = lng;
    this.api.publishSomeData({ serviceId: lng });
    this.services$[lng].serviceName;
    this.activatedRoute.params.subscribe((params) => {
      if (this.currentService == parseInt(params['serviceId'])) ApiService.serviceId = this.currentService;
    });
  }
  getServices() {
    this.api.getServices().subscribe((service) => {
      if (service.status == 200) {
        let data: any[] = [];
        service.body.data.forEach((ele) => {
          let row = {
            serviceName: ele.serviceName,
            serviceType: ele.serviceType,
            deviceCount: ele.deviceCount,
            serviceImage: ele.serviceImage,
          };
          data.push(row);
        });
        this.services$ = data.slice(0);
        this.currentService = this.services$[0].serviceType;
        ApiService.serviceId = this.services$[0].serviceType
      }
    });
  }
  setService() {
    ApiService.serviceId = this.currentService;
    this.api.publishSomeData({ serviceId: this.currentService });
    if (this.Router.url.includes('/Home/dashboard/')) {
      this.Router.navigate(['/Home/dashboard/' + this.currentService])

    }
    this.services$[this.currentService]?.serviceName;
  }
  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed',
    });
  }
  ngOnChanges() {
    this.activatedRoute.params.subscribe((params) => {
      if (this.currentService == parseInt(params['serviceId'])) ApiService.serviceId = this.currentService;
    });
    this.api.getObservable().subscribe((data) => {
      if (data.serviceId > 0) {
        this.currentService = data.serviceId;
        ApiService.serviceId = data.serviceId
      }
      if (data.ImgChange) {
        this.Image = this.ls.getItem('image');
      }
    });
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange(
        {
          sidebarStyle: 'full',
          sidebarCompactToggle: false,
        },
        { transitionClass: true }
      );
    }

    // * --> compact
    this.layout.publishLayoutChange(
      {
        sidebarStyle: 'compact',
        sidebarCompactToggle: true,
      },
      { transitionClass: true }
    );
  }

  logout() {
    this.openLogout = true
  }
  closeLogout() {
    this.openLogout = false

  }
}
