<div class="filter" *ngIf="!full">
    <mat-select (selectionChange)="AdvancedSearch($event)" #select2   placeholder="Sites" [(ngModel)]="site">
        <mat-select-filter *ngIf="select2.focused"  [array]="Sites"  [displayMember]="'siteName'"  (filteredReturn)="SitesCopy =$event"></mat-select-filter>
        <mat-option style="color:#3d4866 !important;" class="filter-select" *ngFor="let item of SitesCopy"
            [value]="item">
            {{item.siteName}}
        </mat-option>
    </mat-select>
</div>
<div class="filter" *ngIf="full">
    <mat-select (selectionChange)="AdvancedSearch($event)" #select2   placeholder="Sites" [(ngModel)]="site">
        <mat-select-filter *ngIf="select2.focused"  [array]="Sites"  [displayMember]="'siteName'"  (filteredReturn)="SitesCopy =$event"></mat-select-filter>
        <mat-option style="color:#3d4866 !important;" class="filter-select" *ngFor="let item of SitesCopy"
            [value]="item">
            {{item.siteName}}
        </mat-option>
    </mat-select>
</div>