import { Component, OnInit } from '@angular/core';
import { Page } from 'app/shared/models/common.model';
import { ApiService } from 'app/shared/services/api.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit {
  LIMIT_LIST=[
    10,
    25,
    50,
    100
  ];
  limit:number=this.LIMIT_LIST[0];
  page:Page={
    totalDocs: 0,
    limit: this.limit,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0
  };
  ticketId:number=0;
  private subscription: Subscription;
  constructor(private api:ApiService) { }

  ngOnInit(): void {
    this.getQuotation(this.page,this.ticketId);
  }
  getQuotation(page:Page,ticketId:number){
   this.subscription =  this.api.getQuotation(page,ticketId).subscribe(res=>{
    })
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    }

}
