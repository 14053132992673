import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select-withsearch',
  templateUrl: './select-withsearch.component.html',
  styleUrls: ['./select-withsearch.component.scss']
})
export class SelectWithsearchComponent implements OnInit {

  constructor() { }
  @Input() options: { value: any, viewValue: string }[] = [];
  @Input() placeHolder=''
  @Output() selectChange = new EventEmitter<any>();
  @Output() optionsRefreshed: EventEmitter<void> = new EventEmitter<void>();

  selectedValue: any={};
   searchText='';
  valueSelcted;
  filteredOptions = [];
  @Input() refreshValue=false
  ngOnInit(): void {
  }
  ngOnChanges(){
    this.filteredOptions = this.options;
    if(this.refreshValue)
      this.refresh()
  }
  filterOptions() {
    const filterValue = this.searchText?.toLowerCase();
    this.filteredOptions = this.options?.filter(option => option.viewValue?.toLowerCase().includes(filterValue));
  }
  refresh(){
    this.selectedValue = null
    this.optionsRefreshed.emit()
  }
}
