import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  @Input() openAdd :boolean = false;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  checked = false;
  constructor(
    public jwtAuth :JwtAuthService
  ) { }

  ngOnInit(): void {
  }
  closeModal(event: any) {
    this.close.emit();
    // this.form.reset();
  }
  closeModalTouch(event: any) {
    event.stopPropagation();
    // this.close.emit();
  }
  clickYesOrNo(allDevices: boolean){
    this.jwtAuth.signout(allDevices);
    this.openAdd = false;
  }
  onChange($event){
  this.checked = $event.checked
  }
}
