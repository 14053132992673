import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { UserRoleGuard } from './shared/guards/user-role.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'Home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session'}
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'Home',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Home', breadcrumb: 'Home'}
      },
      // {
      //   path: 'dashboard',
      //   loadChildren: () => import('./views/dashboard/analytics/analytics.component').then(m => m.AnalyticsComponent),
      //   data: { title: 'dashboard', breadcrumb: 'Dashboard'}
      // },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
        data: { title: 'Profile', breadcrumb: 'PROFILE'}
      },
      {
        path: 'operation',
        loadChildren: () => import('./views/crud-tables/crud-tables.module').then(m => m.CrudTablesModule),
        data: { title: 'operation', breadcrumb: 'operation'}
      },
      {
        path: 'service-requests',
        loadChildren: () => import('./views/ticket/serviceRequest.module').then(m => m.TicketModule),
        data: { title: 'Service Requests', breadcrumb: 'Service Requests'}
      },
      {
        path: 'ticket',
        loadChildren: () => import('./views/ticket/ticket.module').then(m => m.TicketModule),
        data: { title: 'tickets', breadcrumb: 'tickets'}
      },
     
      // {
      //   path: 'mac-list',
      //   loadChildren: () => import('./views/crud-tables/device-mac-list/device-mac-list.component').then(m => m.DeviceMacListComponent),
      //   data: { title: 'mac-list', breadcrumb: 'Mac List'}
      // },
      {
        path: 'inventory',
        loadChildren: () => import('./views/crud-tables/device-inventory/device-inventory.component').then(m => m.DeviceInventoryComponent),
        data: { title: 'inventory', breadcrumb: 'Inventory'}
      },
      {
        path: 'users',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./views/administration/users/users.component').then(m => m.UsersComponent),
        data: { title: 'users', breadcrumb: 'Users'}
      },
      {
        path: 'administration',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./views/administration/administration.module').then(m => m.AdministrationModule),
        data: { title: 'Administration', breadcrumb: 'Administration'}
      },
      {
        path: 'Reports',
        loadChildren: () => import('./views/administration/administration.module').then(m => m.AdministrationModule),
        data: { title: 'Reports', breadcrumb: 'Reports'}
      },
      {
        path: 'ppm',
        loadChildren: () => import('./views/ppm/ppm.module').then(m => m.PpmModule),
        data: { title: 'Ppm', breadcrumb: 'Ppm'}
      }
    ]
  },
 
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

